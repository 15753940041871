@font-face {
  font-family: "FrutigerLTW02";
  src: url("../src/fonts/99f761bf-9f30-4bdd-b284-ee7038dc52c9.eot?#iefix");
  src: url("../src/fonts/99f761bf-9f30-4bdd-b284-ee7038dc52c9.eot?#iefix")
      format("eot"),
    url("../src/fonts/3dfb651c-3644-4f87-b7cb-32889ea939ba.woff2")
      format("woff2"),
    url("../src/fonts/59d9a83f-4045-4d43-af46-655f845461ee.woff") format("woff"),
    url("../src/fonts/f901b503-9104-414a-a856-af9bcc802b5c.ttf")
      format("truetype"),
    url("../src/fonts/e4d07d96-8993-47b9-9f1d-1e228bf1c529.svg#e4d07d96-8993-47b9-9f1d-1e228bf1c529")
      format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "FrutigerLTW02";
  src: url("../src/fonts/c94bd41e-23b3-4522-8237-0a0f7bd0e953.eot?#iefix");
  src: url("../src/fonts/c94bd41e-23b3-4522-8237-0a0f7bd0e953.eot?#iefix")
      format("eot"),
    url("../src/fonts/361c8371-8c18-4a04-9430-ef7cd4bf06ec.woff2")
      format("woff2"),
    url("../src/fonts/409b4bec-c67e-4764-a141-054db8df81d2.woff") format("woff"),
    url("../src/fonts/efe9def0-77d1-4c28-8fd2-371236a3c8ed.ttf")
      format("truetype"),
    url("../src/fonts/70b07d32-76f5-474e-83b0-7d5a3fefb15b.svg#70b07d32-76f5-474e-83b0-7d5a3fefb15b")
      format("svg");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

body {
  margin: 0;
}

:root {
  --space: 1.5em 0;
  --space: 2em 0;
}

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Site-content {
  flex: 1 0 auto;
  padding: var(--space) var(--space) 0;
  width: 100%;
}
